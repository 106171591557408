// @import '/node_modules/bootstrap/scss/bootstrap.scss';
@import '/node_modules/bootstrap/dist/css/bootstrap.css';

$numberColor: #1374d6 !default;
$blueColor: #3ab0e2 !default;
$redColor: #e96656 !default;
$greenColor: #34d293 !default;
$orangeColor: #e7ac44 !default;

.banner { height:250px; overflow: hidden;
  background: url(images/meet.jpg) no-repeat center;
  background-size: cover;
  display: flex;
}
.banner-slogan {
  text-align: center;
  font-size: 2rem;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-weight: bold;
}

.searchJob { margin-top: -50px;}

@mixin skill($size, $color) {
  font-size: $size; 
  // color:$color;
  font-weight: bold;
  border-radius: 40px;
  width: 80px; height: 80px;
  border: 10px solid lighten($color, 30%);
  display: inline-flex; align-items: center; justify-content: center;
  flex-direction: column;
}
@mixin skillTitle($color) {
  color: $color; font-size: 1rem;
  font-weight: bold;
}
$skill-family: () !default;
$skill-family: map-merge((
 "blue": $blueColor,
 "red": $redColor,
 "green": $greenColor,
 "orange": $orangeColor,
), $skill-family);
@each $color, $value in $skill-family {
 .skill#{$color} {
   .number {
     @include skill(1.75rem, $value); 
   }
   .title{
     @include skillTitle($value); 
   }
 }
}

// .tab-content { border: 1px solid red;}


.footer { background: #000020f3; padding: 30px 0px; }
.copyright { font-size: 0.8rem; background: #000018; padding: 10px; text-align: center; color: #fff;}