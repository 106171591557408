// * {
//     font-family: sans-serif;
// }
  .content-table {
    border-collapse: collapse;
    margin: 25px 0px;
    margin-left: 50px;
    font-size: 0.9em;
    border-radius: 5px 5px 0 0;
    // overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    &:hover{
        background-color: #FFFFFF;
        
    }
  }
  
  
  .content-table thead tr {
    background-color: #393972;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
  }
  
  .content-table th,
  .content-table td {
    padding: 12px 15px;
  }
  
  .content-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .content-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .content-table tbody tr:last-of-type {
    border-bottom: 2px solid #393972;
  }
  
  .content-table tbody tr.active-row {
    font-weight: bold;
    color: #393972;
  }
.btncolor{
  color: white;
  background: #393972;
  &:hover{
    color: white;
  }
}